
import CloudFun, { defineComponent, ref, Condition, Operator, reactive } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import { VueUploadItem } from "vue-upload-component";
import "@/global-components/ckeditor/styles.css";
import formatDate from 'xe-utils/toDateString'
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'

export default defineComponent({
  components: {
    Grid,
    SelectBox,
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [
      { field: 'Name' },
      { field: 'Description' }
    ]

    const gridOptions: GridOptions = {
      stripe: false,
      title: '政令宣導',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      multiselect: false,
      printConfig: { sheetName: '政令宣導類別清單', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '政令宣導類別清單', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [
        { field: "Title", title: "標題", showHeaderOverflow: true, showOverflow: true, sortable: true, minWidth: '33%' },
        { field: "Author", title: "作者", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Category.Name", title: "類別", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Date", title: "日期", showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => cellValue ? formatDate(new Date(cellValue), 'yyyy/MM/dd') : '' },
        {
          field: "Ordinal", title: "排序", showHeaderOverflow: true, showOverflow: true, sortable: true, resizable: false, width: 100,
        },
        { field: "Published", title: "已發布", showHeaderOverflow: true, showOverflow: true, sortable: true, resizable: false, width: 100, formatter: ({ cellValue }) => cellValue ? '是' : '否' },
      ],
      promises: {
        query: model ? (params: any) => {
          params.condition = new Condition("Type", Operator.Equal, 2).and(params.condition);
          if (!params.sortings || params.sortings?.length === 0) {
            params.sortings.push({ column: 'Ordinal', order: 0 }, { column: 'CreatedTime', order: 1 });
          }
          return model.dispatch("bulletin/query", params);
        } : undefined,
        queryAll: model ? () => model.dispatch("bulletin/query") : undefined,
        save: model ? (params) => model.dispatch("bulletin/save", params) : undefined,
      },
      modalConfig: { width: "70%", height: "700", showFooter: true },
      // treeConfig: { children: 'Children' }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 80,
      titleAlign: 'right',
      items: [
        {
          field: 'Title',
          title: '標題',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Author',
          title: '作者',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        { field: "CategoryId", title: "類別", span: 12, slots: { default: "column-category" } },
        {
          field: 'Date',
          title: '日期',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
        },
        {
          field: 'Ordinal',
          title: '排序',
          span: 12,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' } }
        },
        {
          field: 'Published',
          title: '已發布',
          span: 12,
          itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
        },
        {
          field: 'ViewCount',
          title: '檢視次數',
          span: 12,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', disabled: 'true' }, }
        },
        { field: "Content", title: "內容", span: 24, slots: { default: "column-content" } },
        {
          span: 24,
          slots: { default: "files" }
        },
      ],
      rules: {
        Title: [{ required: true }],
        Date: [{ required: true }],
        CategoryId: [{ required: true }],
        Author: [{ required: true }],
      }
    }

    const selectorOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇類別",
      textField: "Name",
      valueField: "Id",
      columns: [{ field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true }],
      multiselect: false,
      showHeader: true,
      promises: {
        find: (value: any) => model?.dispatch("bulletinCategory/find", value),
        query: (params: any) => {
          params.condition = new Condition("Published", Operator.Equal, true).and("Type", Operator.Equal, 2).and(params.condition);
          params.sortings.push({ column: 'Ordinal', order: 0 });
          return model?.dispatch("bulletinCategory/query", params)
        },
      }
    };

    const onGridEdit = async(row: any, callback: any) => {
      const entity = row.Id ? await model?.dispatch('bulletin/find', row.Id) : undefined;
      if (entity) {
        Object.assign(row, entity);
        fileModal.show(row);
      }
      callback();
    }

    const formSubmit = (row: any, submit: any) => {
      submit();
    }

    const initData = (row: any, callback: any) => {
      row.Ordinal = 0;
      row.ViewCount = 0;
      row.Type = 2;
      row.Published = false;
      row.Content = { Content: '' };
      callback();
    }

    const classicEditor = ClassicEditor;
    const editorConfig = {
      extraPlugins: [UploadAdapterPlugin],
      toolbar: {
        shouldNotGroupWhenFull: true,
        items: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "alignment",
          "bulletedList",
          "numberedList",
          "outdent",
          "indent",
          "highlight",
          "insertTable",
          "|",
          "link",
          "blockQuote",
          "imageInsert",
          "mediaEmbed",
          // 'codeBlock',
          "htmlEmbed",
          "|",
          "|",
          "undo",
          "redo",
          "sourceEditing"
        ]
      },
      removePlugins: ["Markdown"],
      image: {
        toolbar: [
          "imageTextAlternative",
          "imageStyle:full",
          "imageStyle:side",
          "linkImage"
        ]
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties"
        ]
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph"
          },
          {
            model: "heading1",
            view: { name: "h1", classes: "font-bold text-xl" },
            title: "Heading 1",
            class: "ck-heading_heading1"
          },
          {
            model: "heading2",
            view: { name: "h2", classes: "font-bold text-base" },
            title: "Heading 2",
            class: "ck-heading_heading2"
          }
        ]
      }
    };

    const fileGrid = ref<any>()
    const fileModal = reactive({
      visible: false,
      selectedRow: {} as any,
      uploadOptions: {
        promises: {
          async query() {
            var condition = new Condition("BulletinId", Operator.Equal, fileModal.selectedRow.Id);
            const sortings = [{ column: "CreatedTime", order: 1 }];
            return model?.dispatch('files/query', {
              condition: condition,
              sortings: sortings,
            });
          },
          insert(formData: FormData) {
            return model?.dispatch('files/insert', { formData, mode: 'other', entityId: 'Bulletin_' + fileModal.selectedRow.Id, fileType: 9 });
          },
          delete(id: number) {
            return model?.dispatch('files/delete', id);
          }
        }
      },
      show(row: any) {
        fileModal.selectedRow = row;
        if (fileGrid.value) fileGrid.value.refresh();
      }
    })

    return {
      model,
      grid,
      gridOptions,
      formOptions,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/files`,
      initData,
      onGridEdit,
      formSubmit,
      selectorOptions,
      formatDate,
      classicEditor,
      editorConfig,
      fileGrid,
      fileModal,
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    },
    async uploaderFilter (current: VueUploadItem, original: VueUploadItem, prevent: any) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    },
  }
})
